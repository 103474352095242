const BASE_URL = 'https://clearing-platform-mnet.cp-dev.dev.bitconex.com';


export const environment = {
  BASE_URL,
  CLEARING_API_ENDPOINT: `${BASE_URL!}/partner-api/v1`,
  CLEARING_CUSTOM_API_ENDPOINT: `${BASE_URL!}/clearing-api/v1`,
  PARTNERSHIP_API_ENDPOINT: 'http://localhost:8080',
  ITU_CODE: 'DEU.MNET',
  KEYCLOAK: {
    URL: 'https://keycloak.cp-dev.dev.bitconex.com',
    REALM: 'clearing-realm-mnet',
    CLIENT_ID: 'clearing-gui-client',
    REDIRECT_URI: 'https://mnet.cp-gui.cp-dev.dev.bitconex.com/'
  }
};

